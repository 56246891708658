import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../../abstract/Document';
import { Wedding, Weddings } from '../Weddings';
import type { IWeddingEvent } from './Events.types';

@Identifiable
@Timestampable
export class Event extends AbstractDocument<IWeddingEvent> {
  readonly collections = {};

  get wedding() {
    return Weddings._.getById(this.reference.parent.parent.id);
  }
}

export class Events extends AbstractCollection<Event, IWeddingEvent> {
  static definitions = {
    _: {} as IWeddingEvent,
  };

  static path = 'events';

  static MAIN_EVENT = '6da8cf10-0000-4000-8000-000000000000' as const;

  constructor(document: Wedding) {
    super(document.collection(Events.path), Event);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }
}
