import { createDeepEqualSelector } from 'cms-suppliers/lib/utils/selectors';
import { createSelector } from 'reselect';
import { getScreenTypeByWidth } from '@bridebook/ui/src/utils/get-screen-type-by-width';
import { selectAuthStatus } from 'lib/auth/selectors';
import { IApplicationState } from 'lib/types';

const getAppState = ({ app }: IApplicationState) => app;
const getAppLocale = ({ app }: IApplicationState) => app.locale;
const getNextUrl = ({ app }: IApplicationState) => app.nextUrl;
const getUser = ({ users }: IApplicationState) => users.user;
export const getDevice = (state: IApplicationState) => getAppState(state).device;

/* ############################################################################
 *  MEMOIZED SELECTORS
 * ######################################################################### */

const getDeviceWidth = createSelector(getDevice, (device) => device.width);

/** Based on user-agent from server */
const getIsMobileUA = createSelector(getDevice, ({ isMobileUA }) => isMobileUA);
const getIsTabletUA = createSelector(getDevice, ({ isTabletUA }) => isTabletUA);

/**
 * Returns screen type (mobile, tablet, desktop) for hiding components from the DOM.
 * Screens are aligned with CSS breakpoints.
 */
export const getScreenType = createDeepEqualSelector(
  createSelector([getDeviceWidth, getIsMobileUA, getIsTabletUA], (width, isMobileUA, isTabletUA) =>
    getScreenTypeByWidth({ width, isMobileUA, isTabletUA }),
  ),
  (obj) => obj,
);

/**
 * @deprecated - use getBreakpoints instead
 */
export const getIsLandscape = createSelector(getScreenType, ({ isDesktop }) => isDesktop);

export const getIsMobileSafari = createSelector(
  getDevice,
  ({ isMobileSafari }) => !!isMobileSafari,
);

export const getIsOnline = createSelector(getAppState, ({ online }) => online);

export const selectIsCordova = (state: IApplicationState) => state.app.device.isCordova;
export const getIsIOS = createSelector(getDevice, ({ isiOS }) => isiOS);
export const getIsAndroid = createSelector(getDevice, ({ isAndroid }) => isAndroid);
export const selectLayoutProps = createSelector(
  [getScreenType, selectAuthStatus, selectIsCordova],
  ({ isMobile, isTablet, isDesktop }, isLoggedIn, isCordova) => ({
    isMobile,
    isTablet,
    isLoggedIn,
    isCordova,
    isDesktop,
  }),
);

//@deprecated use getLastPath instead as you can't rely on what is returned by this selector
export const getPreviousPath = createSelector(getAppState, ({ previousPath }) => previousPath);
export const getLastPath = createSelector(getAppState, ({ lastPath }) => lastPath);

export const getPathname = createSelector(getAppState, ({ pathname }) => pathname);
export const getServerPage = createSelector(getAppState, ({ serverPage }) => serverPage);
export const getQuery = createSelector(getAppState, ({ query }) => query);
export const getPreviousSearch = createSelector(
  getAppState,
  ({ previousSearch }) => previousSearch,
);
export const getPreviousSearchQuery = createSelector(
  getAppState,
  ({ previousSearchQuery }) => previousSearchQuery,
);

// This is necessary when comparing URLs because in Android the `nextUrl` will
// not have the locale
export const getNormalizedNextUrl = createSelector(
  [getAppLocale, getNextUrl],
  (locale, nextUrl) => nextUrl?.replace(new RegExp(`^/${locale}`), '') || '',
);

export const getHost = createSelector(getAppState, ({ device }) => device.host);

export const getShortlistTabFromQuery = createSelector(getAppState, ({ query }) => query.category);

export const getIsMobileKeyboardOpened = createSelector(
  getAppState,
  ({ mobileKeyboardOpened }) => mobileKeyboardOpened,
);

export const getUserCreatedAt = createSelector(getUser, (user) => user?.createdAt);
