import type { PartialKeys } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Untrackable } from '../../abstract/Document';
import { getFirestore } from '../../firebase/firestore';
import { Countries, type Country } from '../Countries';
import type { ICountry } from '../Countries.types';
import type { ITask as IWeddingTask } from '../Weddings/Tasks.types';
import type { ITask } from './Tasks.types';
import { isIndexedDBAvailable } from '@firebase/util';
import { getDocsFromCache, loadBundle, namedQuery, type Query } from 'firebase/firestore';
import { mergeDeepRight, omit } from 'ramda';

/**
 * Country-overloaded `ITask`.
 */
export type ITaskRequired = PartialKeys<Required<ITask>, 'tags'>;

@Identifiable
@Untrackable
export class Task extends AbstractDocument<ITask> {
  readonly collections = {};

  /**
   * Extract the country ID from the document reference.
   */
  get country(): ICountry['id'] {
    return this.reference.parent.parent.id as ICountry['id'];
  }

  get(data: false): never;
  get(data?: true): Promise<ITask>;

  /**
   * Recursively merges documents from different countries.
   */
  async get(data?: boolean) {
    if (data === false) {
      throw new Error('DocumentSnapshot is not supported by this implementation.');
    }

    let result = await super.get(true);

    if (this.country !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Tasks.getById(result.id).get(true), result) as ITask;
    }

    return result;
  }
}

export class Tasks extends AbstractCollection<Task, ITask> {
  static definitions = {
    _: {} as ITask,
  };

  static path = 'tasks';

  constructor(document: Country) {
    super(document.collection(Tasks.path), Task);
  }

  /**
   * Extract the country ID from the collection reference.
   */
  get country(): ICountry['id'] {
    return this.reference.parent.id as ICountry['id'];
  }

  /**
   * Recursively merges the sub-collection from different countries of active tasks
   */
  async active() {
    const result = await this.all();

    for (const key in result) {
      if (result[key].active !== true) {
        delete result[key];
      }
    }

    return result as Record<string, ITaskRequired & { active: true }>;
  }

  /**
   * Recursively merges the sub-collection from different countries.
   */
  async all() {
    let result: Record<string, ITaskRequired> = {};

    const queryName = `${this.country}-tasks`;

    try {
      const firestore = getFirestore();

      /**
       * Checks for `globalThis.indexedDB` availability, only present in the browser.
       */
      if (isIndexedDBAvailable() !== true) {
        throw new Error(`IndexedDB is not available.`);
      }

      let query = (await namedQuery(firestore, queryName)) as Query<ITaskRequired>;

      /**
       * If the named query is not yet loaded, fetch the data bundle data from the server.
       */
      if (query == null) {
        const data = await fetch(`/api/countries/${this.country}/seed?v=2`);

        if (data.ok === true) {
          await loadBundle(firestore, data.body);
        }

        query = (await namedQuery(firestore, queryName)) as Query<ITaskRequired>;

        if (query == null) {
          throw new Error(`Unable to load Firestore query '${queryName}' from local cache.`);
        }
      }

      const documents = (await getDocsFromCache(query)).docs;

      for (const document of documents) {
        result[document.id] = document.data();
      }
    } catch (error) {
      result = await this.query<ITaskRequired>().get(true);
    }

    if (this.country !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Tasks.all(), result) as Record<string, ITaskRequired>;
    }

    return result;
  }

  /**
   * Recursively merges the sub-collection from different countries, returning all tasks belonging to a certain group.
   */
  async getByGroup(group: string) {
    const seed = await this.all();
    const result: Record<string, Omit<IWeddingTask, 'assignee' | 'createdAt' | 'custom' | 'period' | 'updatedAt'>> = {};

    for (const key in seed) {
      if (seed[key].group !== group) {
        continue;
      }

      result[key] = { ...omit(['active', 'period', 'tags'], seed[key]), done: false } as any;
    }

    return result;
  }
}
