import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Untrackable } from '../../abstract/Document';
import { getFirestore } from '../../firebase/firestore';
import { Countries, type Country } from '../Countries';
import type { ICountry } from '../Countries.types';
import type { IBadge } from './Badges.types';
import { isIndexedDBAvailable } from '@firebase/util';
import { getDocsFromCache, loadBundle, namedQuery, type Query } from 'firebase/firestore';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Untrackable
export class Badge extends AbstractDocument<IBadge> {
  readonly collections = {};

  /**
   * Extract the country ID from the document reference.
   */
  get country(): ICountry['id'] {
    return this.reference.parent.parent.id as ICountry['id'];
  }

  get(data: false): never;
  get(data?: true): Promise<IBadge>;

  /**
   * Recursively merges documents from different countries.
   */
  async get(data?: boolean) {
    if (data === false) {
      throw new Error('DocumentSnapshot is not supported by this implementation.');
    }

    let result = await super.get(true);

    if (this.country !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Badges.getById(result.id).get(true), result) as IBadge;
    }

    return result;
  }
}

export class Badges extends AbstractCollection<Badge, IBadge> {
  static definitions = {
    _: {} as IBadge,
  };

  static path = 'badges';

  constructor(document: Country) {
    super(document.collection(Badges.path), Badge);
  }

  /**
   * Extract the country ID from the collection reference.
   */
  get country(): ICountry['id'] {
    return this.reference.parent.id as ICountry['id'];
  }

  /**
   * Recursively merges the sub-collection from different countries.
   */
  async all() {
    let result: Record<string, IBadge> = {};

    const queryName = `${this.country}-badges`;

    try {
      const firestore = getFirestore();

      /**
       * Checks for `globalThis.indexedDB` availability, only present in the browser.
       */
      if (isIndexedDBAvailable() !== true) {
        throw new Error(`IndexedDB is not available.`);
      }

      let query = (await namedQuery(firestore, queryName)) as Query<IBadge>;

      /**
       * If the named query is not yet loaded, fetch the data bundle data from the server.
       */
      if (query == null) {
        const data = await fetch(`/api/countries/${this.country}/seed?v=2`);

        if (data.ok === true) {
          await loadBundle(firestore, data.body);
        }

        query = (await namedQuery(firestore, queryName)) as Query<IBadge>;

        if (query == null) {
          throw new Error(`Unable to load Firestore query '${queryName}' from local cache.`);
        }
      }

      const documents = (await getDocsFromCache(query)).docs;

      for (const document of documents) {
        result[document.id] = document.data();
      }
    } catch (error) {
      result = await this.query<IBadge>().get(true);
    }

    if (this.country !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Badges.all(), result) as Record<string, IBadge>;
    }

    return result;
  }
}
