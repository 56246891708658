import { AbstractCollection } from '../../../abstract/Collection';
import { AbstractDocument, Identifiable } from '../../../abstract/Document';
import { Countries } from '../../Countries';
import type { ICountry } from '../../Countries.types';
import { Supplier } from '../Suppliers';
import type { ISupplier } from '../Suppliers.types';
import type { IConfiguration } from './Configurations.types';
import { mergeDeepRight } from 'ramda';

@Identifiable
export class Configuration extends AbstractDocument<IConfiguration> {
  readonly collections = {};

  /**
   * Extract the country ID from the document reference.
   */
  get country(): ICountry['id'] {
    return this.reference.parent.parent.parent.parent.id as ICountry['id'];
  }

  /**
   * Extract the supplier type from the document reference.
   */
  get supplierType(): ISupplier['id'] {
    return this.reference.parent.parent.id as ISupplier['id'];
  }

  get(data: false): never;
  get(data?: true): Promise<IConfiguration>;

  /**
   * Recursively merges documents from different countries.
   */
  async get(data?: boolean) {
    if (data === false) {
      throw new Error('DocumentSnapshot is not supported by this implementation.');
    }

    let result = await super.get(true);

    if (this.country !== '*') {
      result = mergeDeepRight(
        await Countries._.getById('*').Suppliers.getById(this.supplierType).Configurations.getById(result.id).get(true),
        result,
      ) as IConfiguration;
    }

    return result;
  }
}

export class Configurations extends AbstractCollection<Configuration, IConfiguration> {
  static path = 'configurations';

  constructor(document: Supplier) {
    super(document.collection(Configurations.path), Configuration);
  }

  /**
   * Extract the country ID from the collection reference.
   */
  get country(): ICountry['id'] {
    return this.reference.parent.parent.parent.id as ICountry['id'];
  }

  /**
   * Extract the supplier type from the collection reference.
   */
  get supplierType(): ISupplier['id'] {
    return this.reference.parent.id as ISupplier['id'];
  }

  /**
   * Recursively merges the sub-collection from different countries.
   */
  async all() {
    let result = await this.query<IConfiguration>().get(true);

    if (this.country !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Suppliers.getById(this.supplierType).Configurations.all(), result) as Record<
        string,
        IConfiguration
      >;
    }

    return result;
  }
}
