import type { PartialKeys } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Untrackable } from '../../abstract/Document';
import { Countries, type Country } from '../Countries';
import type { ICountry } from '../Countries.types';
import type { ISupplier } from './Suppliers.types';
import { Configurations } from './Suppliers/Configurations';
import { mergeDeepRight } from 'ramda';

/**
 * Country-overloaded `ISupplier`.
 */
export type ISupplierRequired = PartialKeys<Required<ISupplier>, 'tags' | 'typeDetails'>;

@Untrackable
export class Supplier extends AbstractDocument<ISupplier> {
  readonly collections = {
    Configurations: new Configurations(this),
  };

  /**
   * Extract the country ID from the document reference.
   */
  get country(): ICountry['id'] {
    return this.reference.parent.parent.id as ICountry['id'];
  }

  get(data: false): never;
  get(data?: true): Promise<ISupplier>;

  /**
   * Recursively merges documents from different countries.
   */
  async get(data?: boolean) {
    if (data === false) {
      throw new Error('DocumentSnapshot is not supported by this implementation.');
    }

    let result = await super.get(true);

    if (this.country !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Suppliers.getById(result.id).get(true), result);
    }

    return result;
  }
}

export class Suppliers extends AbstractCollection<Supplier, ISupplier> {
  static path = 'suppliers';

  constructor(document: Country) {
    super(document.collection(Suppliers.path), Supplier);
  }

  /**
   * Extract the country ID from the collection reference.
   */
  get country(): ICountry['id'] {
    return this.reference.parent.id as ICountry['id'];
  }

  /**
   * Recursively merges the sub-collection from different countries.
   */
  async all() {
    let result = await this.query().get(true);

    if (this.country !== '*') {
      result = mergeDeepRight(await Countries._.getById('*').Suppliers.all(), result);
    }

    return result as Record<string, ISupplierRequired>;
  }
}
